<template>
  <v-toolbar flat class="mb-4">
    <v-toolbar-title>
      {{ title }}
      <span></span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mx-2"
            fab
            small
            color="accent"
            router
            to="/tag"
            v-on="on"
          >
            <v-icon dark>mdi-apps</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("tag.name") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mx-2"
            fab
            small
            color="primary"
            router
            to="/tag/create"
            v-on="on"
            v-if="permitted('Tag.Create')"
          >
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("common.create") }}</span>
      </v-tooltip>
    </div>
  </v-toolbar>
</template>

<script>
export default {
  name: "TagToolbar",
  props: {
    title: String,
  },
};
</script>

<style>
</style>